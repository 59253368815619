<template>
  <router-view />
</template>

<style lang="scss">
@import "~bootstrap/scss/bootstrap";
html,
body {
  // margin: 0;
  background: #06b17e;
  // width: 100%;
  // height: 100%;
}
// #app {
//   width: 100vw;
//   height: 100vh;
// }
.private {
  padding: 2rem;
}
header {
  padding: 2rem;
}
.static-logo {
  height: 100px;
}
.textLogo {
  font-size: 28px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
}
</style>
